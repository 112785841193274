
@media (min-width:960px) {
   
    ::-webkit-scrollbar {
        width: 16px;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        height: 60px;
        border-radius: 10px;
        border: 6px solid transparent;
        background: #c4c8cb;
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        height: 60px;
        border-radius: 10px;
        border: 6px solid transparent;
        background: #a19f9d;
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
        -webkit-border-radius: 3px;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track-piece {
        background: '#f7f7f7';
    }
    
}